.content {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

.inputHeader {
  padding: 0 20px;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  background-color: #ffffff;
  border-bottom: solid 1.5px var(--gray-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
@media (max-width: 500px) {
  .inputHeader {
    padding: 0 1rem;
  }
}
.asterisk {
  font-size: 0.6rem;
  color: #dd3636;
  margin-top: 0.8rem;
  margin-right: 0.5rem;
}

.inner {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 15px 15px 0px 0px;
  overflow: auto;
  box-shadow: 0 10px 25px 0 rgb(0 0 0 / 60%);
  overflow-x: hidden;
  overflow-y: scroll;
}

.errorsBox {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.errorMessageBox {
  width: 100%;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pageTitle {
  text-align: center;
}

.inputDetailBox {
  width: 90%;
  margin: 0 auto;
  margin-top: 6rem;
  margin-bottom: 7rem;
}

.inputDetailContents {
  width: 100%;
}

.inputBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.inputBox input {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  margin-bottom: 1rem;
  outline: none;
}

.subtitle {
  text-align: left;
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 0.4rem 0.8rem;
  letter-spacing: 0.1rem;
  border-radius: 5px;
  font-weight: 700;
}
@media (max-width: 500px) {
  .subtitle {
    font-weight: 600;
  }
}

.label {
  margin-top: 1rem;
}

.errorMessage {
  display: flex;
}
.contentBox {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
}

.subtitle {
  text-align: left;
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 0.4rem 0.8rem;
  letter-spacing: 0.1rem;
  border-radius: 5px;
  font-weight: 700;
}
@media (max-width: 500px) {
  .subtitle {
    font-weight: 600;
  }

  .contentBox {
    padding: 0.5rem;
  }
}

.uploadImageButton input {
  display: none;
}

.spotImageBoxContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3rem;
  margin: 1rem 0;
  height: calc(25vw * 0.75);
  width: 100%;
}

.image {
  object-fit: cover;
  width: 25vw;
  height: calc(25vw * 0.75);
}

.spotImageBox {
  width: 25vw;
  height: calc(25vw * 0.75);
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: aliceblue;
  align-items: center;
  justify-content: flex-end;
}

.addImageButtonBox {
  height: 100%;
  /* min-height: 100px; */
  width: auto;
  display: flex;
  justify-content: flex-start;
  padding: 0 2rem;
  margin-left: 3rem;
  align-items: center;
}

.closeButtonBox {
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  top: 0;
  right: 1%;
  background-color: var(--gray-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeBtn {
  color: #111111;
  font-size: 1.5rem;
  border: none;
  padding: 0;
}
/* モバイル・タブレット */
@media (max-width: 900px) {
  .spotImageBoxContainer {
    gap: 1rem;
    margin: 0.5rem 0;
  }

  .addImageButtonBox {
    padding: 0 1rem;
    margin-left: 0.5rem;
  }
  .closeBtn {
    font-size: 1rem;
  }

  .closeButtonBox {
    width: 1.2rem;
    height: 1.2rem;
  }
}
