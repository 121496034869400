.loadingIconBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10rem;

}

.loadindIconContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}

.loading {
  margin-top: 2rem;
}
