.pageTitle {
  margin-top: 3%;
  margin-left: 45px;
}

.userPolicyBox {
  width: 70%;
  text-align: center;
  margin: 0 auto;
}

@media (max-width: 500px) {
  .pageTitle {
    margin-left: 1rem;
  }
  .userPolicyBox {
    width: 90%;
  }
}

.userPolicyBox h2,
.userPolicyBox h3,
.appPolicyBox h3,
.appPolicyBox h4 {
  text-align: left;
}
.userPolicyBox p {
  text-align: left;
  line-height: 2rem;
}

.articleBox p {
  padding: 0;
  margin-left: 1rem;
}

.subArticleBox {
  margin-left: 1rem;
}

.contactBox {
  margin-left: 1rem;
}
