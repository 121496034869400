.inputScheduleBox {
  margin-top: 65px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* progressHeaderBoxと同じ */
}

.mapMenuBox {
  position: absolute;
  top: 0;
  right: 50%;
  transform: translateX(50%);
  z-index: 2;
  width: 100%;
  height: auto;
  min-height: 70px;
  background-color: rgba(217, 217, 217, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapMenuContents {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.backButtonBox {
  position: absolute;
  top: 0;
  left: 3%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backButton {
  color: black;
  font-size: 1.1rem;
  width: 70px;
  padding: 7px;
}

.backButton span {
  margin-left: 5px;
}

.instructionBox {
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.faTimes {
  background-color: var(--gray-color);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.4rem 0.4rem;
  margin-left: 1rem;
  z-index: 1;
}

.routeDataFileUpload {
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.routeDataFileUpload input {
  cursor: pointer;
}

.clearButton {
  width: 20px;
  color: #000000;
  font-weight: 700;
  cursor: pointer;
  margin-left: 5px;
}

.clearButton {
  width: 20px;
  height: 20px;
  color: var(--gray-color);
  font-size: 18px;
  cursor: pointer;
  padding: 0.5rem;
}
.instruction {
  margin: 0.5rem;
  margin-left: 0.7rem;
  font-size: 1.2rem;
}

.howToAdd {
  text-align: left;
  margin-bottom: 1rem;
}

.routeDataFileUpload input {
  cursor: pointer;
}

.importButton {
  padding: 3px;
}

.label {
  background-color: var(--blue);
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.scheduleMapBox {
  position: relative;
  height: 100%;
  z-index: 1; /*ScheduleDetail_contentの下層*/
}

.scheduleMap {
  width: 100%;
  height: 100%;
}

/* for mobile */

.bottomSheetContainer {
  display: none;
}

.sideSheetContainer {
  position: absolute;
  z-index: 2;
  left: -30%;
  top: 25%;
  width: 30%;
  height: 450px;
  max-height: 65%;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
  background-color: var(--gray-color);
}

@media (max-width: 900px) {
  .sideSheetContainer {
    left: -50%;
    width: 50%;
  }
}

@media (max-width: 500px) {
  .sideSheetContainer {
    display: none;
  }

  .bottomSheetContainer {
    display: block;
  }
}

.toggleListButton {
  position: absolute;
  top: calc(50% - 24px);
  left: 100%;
  border: 0;
  width: 34px;
  height: 48px;
  cursor: pointer;
  z-index: 0;
  background-color: var(--gray-color);
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
  border-left: 1px solid #dadce0;
  border-radius: 0 8px 8px 0;
  padding: 10px 25px 10px 0;
}
