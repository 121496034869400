.planInfoBox {
  margin-top: 1rem;
}
.planContentsBox {
  width: 70%;
  margin: 0 auto;
  overflow-x: hidden;
}

.applyButtonBox {
  text-align: center;
}
.applyButtonBox p {
  margin: 0;
  margin: 0.4rem 0;
}

.applyButtonBox button {
  margin: 0 10px;
}

@media (max-width: 500px) {
  .applyButtonBox {
    font-size: 0.8rem;
  }
}

.fixedContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  overflow-y: scroll;
}
.previewContainer {
  padding-top: 25px;
  padding-bottom: 50px;
}

.topHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.optionBar {
  display: flex;
  align-items: flex-end;
  margin-right: 3rem;
}

.optionBar div {
  margin-top: 1.5px;
}

@media (max-width: 770px) {
  .topHeader {
    height: 3rem;
  }
  .optionBar {
    display: flex;
    align-items: flex-end;
    margin-right: 1rem;
    margin-bottom: 1rem;
    max-width: 70%;
  }
}

.faIcon {
  font-size: 0.8rem;
}

.editPenButton,
.joinModalOpener,
.copyButton,
.copiedButton,
.deleteButton {
  display: flex;
  white-space: nowrap;
  width: 80px;
  padding: 0.4rem 0.8rem 0.15rem 0.8rem;
  font-size: 0.85rem;
  border: 1px solid transparent;
  color: black;
  background-color: var(--gray-color);
  border-radius: 15px;
  margin-right: 5px;
  align-items: center;
  flex-direction: column;
  letter-spacing: 1px;
}

.deleteButton {
  background-color: var(--red);
  color: #ffffff;
}

.joinModalOpener {
  width: 100px;
  background-color: var(--primary-color);
  color: #ffffff;
}

.copiedButton {
  border: 1px solid var(--primary-color);
  background-color: #ffffff;
  color: var(--primary-color);
  border-radius: 15px;
}

@media (max-width: 500px) {
  .editPenButton,
  .joinModalOpener,
  .copyButton,
  .copiedButton,
  .deleteButton {
    right: 1rem;
    width: 55px;
    font-size: 0.7rem;
    letter-spacing: 0.8px;
  }
  .copiedButton {
    font-size: 0.6rem;
  }
  .joinModalOpener {
    width: 80px;
  }
}
.copyInfoButtonBox {
  text-align: right;
  display: flex;
}
.copiedButton:hover {
  cursor: not-allowed;
}
.copyInfoButton {
  font-size: 1rem;
  padding: 0.5rem 0;
  color: black;
}

.copiedInfoButton {
  font-size: 1rem;
  padding: 0.5rem 0.6rem;
  color: var(--gray-color);
}

.titleBox {
  margin-bottom: 1.5rem;
}
.originalPlan,
.originalPlan a {
  color: #000000;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

.deletedOriginalPlan,
.deletedOriginalPlan a {
  color: var(--gray-color);
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
.planContent {
  width: 80%;
  margin: 0 auto;
}
.regionContent {
  margin-top: 1rem;
}

.host,
.disclosure {
  display: flex;
}

.iconImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 1px var(--gray-color);
  margin-right: 1rem;
}

.infoBox {
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-start;
}

.applyMethodInfoBox {
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-start;
  flex-direction: column;
}

.infoLabel {
  display: inline-block;
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  border-radius: 50px;
  width: auto;
  padding: 0.2rem 1rem;
  margin-right: 1rem;
  white-space: nowrap;
}

.infoContent {
  font-weight: 600;
  margin: auto 0;
  color: #111111;
}

.tag {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.2rem 1.6rem;
  display: inline-block;
  width: auto;
  border: none;
  background-color: var(--gray-color);
  border-radius: 15px;
}

.mapBox {
  margin: 1.5rem 0;
  width: 100%;
  height: 800px;
}

@media (max-width: 900px) {
  .mapBox {
    height: 600px;
  }
}

@media (max-width: 500px) {
  .mapBox {
    height: 500px;
  }
}
.planExplanation,
.notes {
  white-space: pre-wrap;
  line-height: 1.7;
}

.planExplanation {
  margin: 1rem 0;
}

.tourInfoBox {
  margin: 1rem 0;
}

.infoSection {
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 0.3rem 0.8rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  border-radius: 5px;
  font-weight: 900;
  /* font-size: 0.8rem; */
}

.mapContent .howLong {
  font-size: 0.8rem;
  margin: 0.6rem 0;
  text-align: right;
}

.scheduleList {
  margin: 0;
}

.scheduleRow {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 90px;
}

.scheduleIndex {
  position: relative;
  padding: 5px 0;
  background-color: white;
  font-weight: bold;
  color: #333;
  z-index: 1;
}

.timeSchedule {
  width: 100%;
}

/* タイムラインのライン */

.timeline,
.firstTimeline,
.lastTimeline {
  position: absolute;
  border-left: 2px solid var(--gray-color);
  height: 100%;
  width: 10px;
  top: 0%;
  margin-left: 5px;
}
.firstTimeline {
  top: 50%;
  height: 50%;
}
.lastTimeline {
  height: 50%;
}

/* 時間表示のコンテナ */
.timeBox {
  margin-right: 10px;
}

/* 時間表示 */
.time {
  font-weight: bold;
  color: black;
}

.scheduleBox {
  border-radius: 5px;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  max-height: 300px;
  margin-left: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 15px 10px 15px 10px;
  vertical-align: middle;
  background: #efefef;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
}

.scheduleTitle {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 5px;
}

.scheduleMemo {
  height: auto;
  width: 90%;
  max-height: 200px;
  overflow-y: scroll;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}

.goodButton {
  color: var(--gray-color);
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.goodButtonBox {
  margin: 0 4%;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.faHeart {
  font-size: 2rem;
}

#modalBox {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  transition: transform 0.3s;
}
#modalContent {
  position: fixed;
  width: 100%;
  padding: 16px;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 4px 4px 0 0;
  z-index: 11;
  transition: transform 0.4s;
  text-align: center;
}

.hidden {
  transform: translate(0, 100vh);
}

.fa-times {
  color: #000000;
  background-color: #dddddd;
  padding: 0.5rem 0.5rem;
  border-radius: 50%;
  margin-right: 0.6rem;
  font-weight: 300;
  font-size: 1rem;
}

.close-button-box {
  margin-top: 2rem;
}

.closeButton {
  text-align: right;
  width: 30px;
  height: 30px;
  color: #000000;
  background-color: #dddddd;
  padding: 0.5rem 0.5rem;
  border-radius: 50%;
}

.closeButton {
  text-align: right;
}

.fixedPlusButton {
  text-align: center;
  width: 70px;
  height: 70px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  color: #ffffff;
  border-radius: 50%;
  background-color: var(--blue-button-color);
  border: none;
}

/* for tablet , mobile */

@media (max-width: 500px) {
  .planContent {
    width: 90%;
  }

  .planExplanation {
    font-size: 0.8rem;
    white-space: pre-wrap;
  }

  .applyButtonBox {
    font-size: 0.8rem;
  }

  .region,
  .host,
  .disclosure {
    font-size: 0.8rem;
    padding: 0.2rem 0.6rem;
    display: flex;
    align-items: center;
    height: 25px;
  }

  .tag {
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
  }

  .faHeart {
    font-size: 1.2rem;
  }

  .info {
    font-size: 0.8rem;
    margin: auto 0;
  }
}

/* GA測定のためのclass、スタイルを付けないとclassが付与されない */
.registrationPlan {
  border: none;
}
.updatePlan {
  border: none;
}
.policyList {
  display: flex;
  justify-content: center;
}
