/* 戻るボタン */
.backButton {
  position: relative;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none;
  margin-left: 45px;
  /* margin-bottom: 1rem; */
  background-color: var(--primary-color);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding: 0.5rem 0.5rem;
}

.arrowLeftLong {
  color: #ffffff;
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}
@media (max-width: 500px) {
  .arrowLeftLong {
    font-size: 1.2rem;
  }
  .backButton {
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 5px;
    /* margin-bottom: 1rem; */
  }
}
