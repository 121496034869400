/* h1 */
.pageTitle {
  margin-top: 3%;
  margin-left: 45px;
}

.navList {
  display: flex;
  width: 80%;
  margin: 10px auto;
  list-style: none;
  padding: 0;
  justify-content: space-evenly;
}
.navList div {
  color: aqua;
}
.navItem {
  margin: 0;
  /* color: #fff; */
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 20px;
  white-space: nowrap;
}
@media (max-width: 500px) {
  .pageTitle {
    margin-left: 1rem;
  }
  .navList {
    flex-direction: column;
  }
}
.navItemActive {
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 1.1rem;
  font-weight: 600;
  white-space: nowrap;
}

.subtitle {
  text-align: center;
  color: var(--primary-color);
}

/* for mobile */
@media (max-width: 730px) {

  .pageTitle {
    width: 90%;
  }

  .navItem {
    font-size: 0.8rem;
  }

  .navItemActive {
    font-size: 0.9rem;
  }
}
