.sheetContent {
  position: relative;
  height: 100%;
  width: 100%;
  background: aliceblue;
}

.changeListTab {
  width: 100%;
  height: 50px;
  border-bottom: solid 1.5px var(--gray-color);
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.tab {
  border-radius: 10px;
  padding: 5px;
}

.listBox,
.infoBox {
  width: 100%;
  height: 100%;
  background: aliceblue;
  overflow-y: scroll;
}

.listHeaderBar {
  width: 100%;
  height: 50px;
  z-index: 3;
  position: absolute;
  top: -3px;
  background-color: aliceblue;
}

.listHeaderBar p {
  margin: 8px 0;
  font-weight: 600;
}

.listHeaderBar p {
  margin: 8px 0;
  font-weight: 600;
}

.fixedPlusButtonBottom,
.fixedPlusButtonMiddle {
  text-align: center;
  width: 70px;
  height: 70px;
  position: fixed;
  bottom: 7%;
  right: 5%;
  color: #ffffff;
  border-radius: 50%;
  background-color: var(--blue-button-color);
  border: none;
  z-index: 1;
}

.fixedPlusButtonMiddle {
  bottom: 55%;
}

.faPlus {
  font-size: 3rem;
}
