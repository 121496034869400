.pageTitleClass {
  margin-top: 3%;
  margin-left: 45px;
}

@media (max-width: 500px) {
  .pageTitleClass {
    margin-left: 1rem;
  }
}

.communityPolicyContent h3 {
  margin-top: 5rem;
  text-align: center;
  color: var(--primary-color);
  font-size: 1.2rem;
}

.lastCopy {
  margin-top: 3rem;
}

.communityPolicyContent {
  width: 70%;
  margin: 0 auto;
}

.wantToValue p {
  margin: 3rem 0;
}

.wantToValue,
.pleaseBox {
  margin-top: 5rem;
  margin-bottom: 8rem;
}

.pleaseBox p {
  line-height: 2rem;
}

.pleaseBox {
  margin-bottom: 10rem;
}

.pleaseBox p{
  margin-bottom: 8rem;
}

.enjoyBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgBox {
  text-align: center;
  margin: 0 auto;
  width: 15%;
  display: flex;
  justify-content: space-around;
}

.imgPeople1,
.imgPeople2,
.imgPeople3,
.imgPeople4 {
  width: 100%;
  margin: 3rem;
  height: auto;
}

.imgPeople3,
.imgPeople4 {
  margin-top: 2rem;
}

.participateButton {
  margin-top: 3rem;
}

@media (max-width: 500px) {
  .communityPolicyContent h3 {
    margin-top: 2rem;
  }

  .wantToValue,
  .pleaseBox {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .imgBox {
    width: 30%;
  }

  .participateButton {
    margin-top: 1rem;
  }
}
