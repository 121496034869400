.mypageButton,
.toPlanButton,
.toCreatePlan {
  border: none;
  background-color: var(--primary-color);
  color: #ffffff;
  border-radius: 10px;
  padding: 0.4rem 2rem;
  margin: 1rem 0;
  font-size: 1rem;
}

.buttonBox .mypageButton,
.buttonBox .toCreatePlan {
  margin: 1rem 0.5rem;
}

.overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
}

.modalContent {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 10px;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  z-index: 2;
  text-align: center;
}

.modalContent img {
  max-width: 100%;
}

@media (max-width: 650px) {
  .modalContent {
    width: 80%;
  }
}
