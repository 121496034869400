/* footer */
footer {
  background-color: var(--primary-color);
}

.mobileFooterBox {
  display: none;
}

footer ul,
footer li {
  padding: 0;
  list-style: none;
}

footer a {
  font-weight: 100;
  text-decoration: none;
  color: #ffffff;
}

.footerBox {
  justify-content: space-between;
  display: flex;
  margin-top: 2rem;
}
.footerItem {
  width: 100%;
  margin: 1rem 0 1rem 1rem;
}

.footerIconBox {
  margin: 1rem 0 0.5rem 2rem;
}

.footerBlock1 {
  display: flex;
  margin-left: 2rem;
  margin-bottom: 1rem;
}
.footerBlock1 a {
  margin-right: 1rem;
}
.footerBlock2 {
  display: flex;
  justify-content: center;
}
.footerBlock2 a {
  margin-right: 1rem;
}

.faFacebookSquare,
.faInstagram {
  color: #ffffff;
  font-size: 2rem;
  cursor: pointer;
}

#copyright {
  font-size: 0.7rem;
  padding-bottom: 0.5em;
  text-align: center;
}

@media (max-width: 1060px) {
  .footerBlock {
    margin-right: 1em;
  }

  .footerItem {
    font-size: 0.8rem;
    margin: 1rem 0 1rem 2rem;
  }

  #copyright {
    padding-bottom: 0.5rem;
    text-align: center;
  }
}

@media (max-width: 500px) {
  /* モバイル/タブレット版 */
  .footerMobileBlock1 {
    display: flex;
    margin-left: 2rem;
    flex-direction: column;
    margin-bottom: 0.8rem;
  }

  .footerMobileBlock1 li {
    margin-right: 1rem;
    margin-bottom: 0.7rem;
    padding: 2px;
  }
  .footerMobileBlock2 {
    display: flex;
    font-size: 0.8rem;
    justify-content: center;
  }

  .footerMobileBlock2 li {
    margin-right: 1em;
  }

  .footerBox {
    display: none;
  }

  .mobileFooterBox {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
  }

  #copyright {
    font-size: 0.65rem;
    text-align: center;
    padding-bottom: 1rem;
  }
}
