#invisible {
  opacity: 0;
}

/* シンプルなフェードイン */
.fadeIn {
  animation-name: FadeIn;
  animation-timing-function: ease;
  animation-duration: 2s;
  opacity: 1;
}
@keyframes FadeIn {
  0% {
    opacity: 0;
    /* transform: translateX(100px); */
  }

  100% {
    opacity: 1;
    /* transform: translateX(0); */
  }
}

/* 左からのフェードイン */

/*自転車画像ごとの時間差を設定(トップページ)*/
#slideInAll {
  animation: SlideInLeft 7s;
  animation-timing-function: ease;
}
#slideInFast {
  animation: SlideInLeft 5s;
  animation-timing-function: ease-out;
}

#slideInMiddle {
  animation: SlideInLeft 5s;
  animation-timing-function: linear;
}

#slideInSlow {
  animation: SlideInLeft 5.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.5s;
}
#slideInVerySlow {
  animation: SlideInLeft 5.8s;
  animation-timing-function: ease-in;
  animation-delay: 1s;
}
@keyframes SlideInLeft {
  0% {
    transform: translateX(90%);
  }

  100% {
    transform: translateX(0);
  }
}
/* .fadeInLeft {
  transform: translateX(0px);
  opacity: 1;
}

.fadeInRight {
  transform: translateX(0px);
  opacity: 1;
}

.fadeInBtm {
  transform: translateY(0px);
  opacity: 1;
}

.zoomIn {
  transform: scale(1);
  opacity: 1;
} */
