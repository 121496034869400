.search-ride-events {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tagsBar {
  margin: 0 auto;
  display: flex;
  grid-gap: 5px;
  padding: 5px;
  width: 90%;
  min-height: 60px;
  flex-wrap: wrap;
  justify-content: center;
}

.tagBox {
  position: relative;
  display: flex;
  cursor: pointer !important;
  justify-content: center;
  transition: all 0.3s ease;
}
.tag {
  display: flex;
  border: none;
  width: auto;
  margin: 0 1.2rem;
  min-width: 65px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.tagBox::after {
  position: absolute;
  width: 40%;
  content: "";
  height: 2px;
  top: calc(100% + 1px);
  opacity: 0;
  background-color: var(--primary-color);
}
.tagBox:hover::after {
  opacity: 0.1;
}

@media (max-width: 600px) {
  .tagsBar {
    padding: 5px;
    width: 100%;
    height: 50px;
    overflow-y: hidden;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .tag {
    font-size: 13px;
    border: none;
    cursor: pointer !important;
    width: auto;
    min-width: 50px;
    margin: 0 0.3rem;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    transition: all 0.3s ease;
  }
}

.unSelectedTag {
  color: rgb(113, 113, 113);
}
.selectedTag {
  color: black;
  font-weight: 600;
}

.selectedTag::after {
  position: absolute;
  width: 40%;
  content: "";
  height: 2px;
  top: calc(100% + 1px);
  opacity: 1;
  background-color: var(--primary-color);
}

.selectedTag:active::after {
  opacity: 0;
}

.tag:hover {
  color: black;
}
.tag:active {
  transform: scale(0.9);
}

.search-bar {
  text-align: center;
  display: flex;
  height: 66px !important;
  width: 100% !important;
  padding: 5px;
  margin: 20px 5px 20px 5px;
  border: 1px solid #dddddd !important;
  border-radius: 32px !important;
  color: #222222 !important;
  box-shadow: 0px 16px 32px rgb(0 0 0 / 15%), 0px 3px 8px rgb(0 0 0 / 10%) !important;
  background-color: #ffffff !important;
  justify-content: space-between;
}

#btn-search {
  width: 200px;
  max-width: 200px !important;
  height: 48px !important;
  border: 0px !important;
  color: #ffffff !important;
  border-radius: 24px !important;
  position: relative !important;
  vertical-align: middle !important;
  background: linear-gradient(to right, #e61e4d 0%, #e2185b 50%, #cc146a 100%) !important;
}

.input-container {
  display: flex;
  padding: 14px 24px !important;
  align-items: center;
  padding: 14px 24px !important;
}

.input-container input {
  height: 30px;
  width: 100%;
  border: none;
}

.input-title {
  display: flex;
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 800 !important;
  letter-spacing: 0.04em !important;
  padding-bottom: 3px !important;
}
