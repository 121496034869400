.content {
  z-index: 3;
  position: fixed;
  bottom: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
  background-color: #ffffff;
  width: 100%;
  height: 100vh;
  padding: 0;
  overflow: auto;
  box-shadow: 0 10px 25px 0 rgb(0 0 0 / 60%);
}

.backButton {
  position: absolute;
  top: 1rem;
  left: 1rem;
  cursor: pointer;
  color: black;
}

.pageTitle {
  margin-top: 1rem;
  text-align: center;
}

.scheduleBox {
  width: 100%;
  margin: 0 auto;
  margin-top: 6rem;
  margin-bottom: 7rem;
  text-align: center;
}

.scheduleTitle {
  margin-top: 2rem;
  width: 80%;
  height: 3rem;
  background-color: var(--gray-color);
  border-radius: 1.5rem;
  color: black;
}
