.listContainer {
  padding-top: 50px;
  margin-bottom: 0.5rem;
  width: 100%;
  height: 100%;
}

.fixedPlusButton {
  text-align: center;
  width: 70px;
  height: 70px;
  position: fixed;
  right: 5%;
  color: #ffffff;
  border-radius: 50%;
  background-color: var(--blue-button-color);
}

.faPlus {
  font-size: 3rem;
}

.listContents {
  display: flex;
  flex-direction: column;
  position: relative;
}
.content {
  width: 100%;
  height: auto;
  display: flex;
  border-bottom: solid 1px #111111;
  padding: 0.8rem 1rem;
  flex-direction: column;
}

.scheduleTitleBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.scheduleTitle {
  width: 90%;
  min-height: 70px;
  text-align: left;
  margin-bottom: 0.3rem;
  cursor: pointer;
}

.scheduleTitleRight {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: row;
  margin-right: 1%;
}

.scheduleEditOrDelete {
  width: 100%;
  display: flex;
  height: 20%;
  flex-direction: column;
}

.scheduleEditOrDelete button {
  font-size: 1.5rem;
  width: 50px;
  height: 50px;
  padding: 8px;
  color: #111111;
}

.scheduleDetail {
  margin-bottom: 1rem;
}

.addScheduleButtonBox {
  width: 100%;
  z-index: 3;
  position: sticky;
  bottom: 0;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
}

.dummyBlueButton {
  font-size: 0.85rem;
  font-weight: 600;
  background-color: var(--blue-button-color);
  color: #ffffff;
  border-radius: 25px;
  padding: 3px 8px;
}

@media (max-width: 602px) {
  .scheduleTitleBox {
    margin-top: 0.1rem;
  }

  .addScheduleButtonBox {
    font-size: 0.8rem;
  }

  .dummyBlueButton {
    font-size: 0.7rem;
  }
}

.meet,
.dismiss {
  display: flex;
  align-items: center;
}

.scheduleDetail {
  position: relative;
  margin: 0.3rem 0;
  overflow-wrap: anywhere;
  max-width: 80%;
}

.scheduleDetail tr {
  text-align: left;
  padding-top: 4px;
}

.scheduleDetail td {
  padding-top: 3px;
}

.scheduleDetail a {
  padding: 5px 5px;
}

.tdTitle {
  width: 80px;
  margin-right: 1rem;
  font-weight: 600;
}

/* for mobile */
@media (max-width: 500px) {
  .scheduleDetail td {
    display: flex;
    text-align: left;
  }

  .tdTitle {
    margin-bottom: 5px;
    width: 60px;
    font-weight: 600;
    overflow: hidden;
    margin-right: 0.5rem;
  }

  .scheduleEditOrDelete button {
    font-size: 18px;
    width: 35px;
    height: 35px;
    padding: 7px;
  }
}

.scheduleEditButton {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.detailOpenButton {
  color: black;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.detailOpenButton p {
  margin: 0;
  margin-right: 0.5rem;
  font-size: 0.9rem;
}

.place {
  margin-bottom: 2rem;
}

.icon {
  font-size: 2.5rem;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

@media (max-width: 500px) {
  .icon {
    font-size: 1.5rem;
  }
}
