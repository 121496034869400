.main {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.comingsoon {
  font-size: 1rem;
  padding: 1rem 3rem;
  font-weight: 600;
  border: none;
  background-color: var(--primary-color);
  color: #ffffff;
  border-radius: 25px;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
}

@media (max-width: 1050px) {
  .comingsoon {
    font-size: 0.8rem;
    padding: 0.8rem;
  }
}
@media (max-width: 551px) {
  .comingsoon {
    width: 70%;
  }
}
/* main */

.imageBox {
  position: relative;
  margin-bottom: 5rem;
  width: 100%;
}

.backImageBoxPC {
  position: relative;
  z-index: 0;
}

.backImageBoxPC img {
  width: 100%;
  object-fit: cover;
}

.backImageBoxMob {
  display: none;
}

/*モバイル・タブレット/*/
@media (max-width: 900px) {
  .imageBox {
    position: relative;
    margin-bottom: 1rem;
    z-index: -1;
  }
  .backImageBoxMob {
    display: block;
    width: 100%;
    position: relative;
    animation: FadeIn 2s;
  }
  .backImageBoxMob img {
    width: 100%;
    height: 90%;
  }
  .backImageBoxPC {
    display: none;
  }
}

.treeImg {
  position: absolute;
  top: 36%;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  height: auto;
  /* z-index: 1; */
}

.peopleBox {
  width: 100%;
  display: flex;
  position: absolute;
  top: 58%;
  justify-content: space-evenly;
  /* z-index: 2; */
}

.peopleImg {
  margin: auto;
  width: 16%;
  height: auto;
}

/*モバイル・タブレット*/
@media (max-width: 900px) {
  .treeImg {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: auto;
    z-index: 1;
  }
  .peopleBox {
    width: 100%;
    display: flex;
    position: absolute;
    top: 70%;
    justify-content: space-evenly;
    z-index: 2;
  }
}

.pobiExplainBox {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 70%;
}

@media (max-width: 750px) {
  .pobiExplainBox {
    width: 85%;
  }
}

@media (max-width: 550px) {
  .pobiExplainBox {
    margin: 0 1rem;
    width: auto;
  }
}

.pobiExplainBox p {
  margin: 0;
  line-height: 2.5rem;
  text-align: center;
}

.introBox {
  opacity: 0;
}
.catchCopy {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.catchCopy h1 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--primary-color);
}
.catchCopyMob {
  display: none;
}
.leadSentence {
  width: 90%;
}
.leadSentence p {
  text-align: center;
}

/*モバイル/タブレット*/
@media (max-width: 1000px) {
  .catchCopyMob {
    display: block;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  .catchCopyMob h1 {
    font-size: 100%;
    font-weight: bold;
    color: #111111;
  }
  .logoMob {
    width: 150px;
    height: auto;
    margin: 0.5rem 0;
  }
  .catchCopy,
  .catchCopyInVisible {
    display: none;
  }
  .catchCopyMobInVisible {
    opacity: 0;
  }
  .pobiExplainBox p {
    line-height: 2;
  }
}

.subTitle {
  margin-bottom: 2rem;
  text-align: center;
  color: var(--primary-color);
}

.buttonBox {
  margin-top: 2rem;
  text-align: center;
}
.canDoList {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.canDoContent {
  width: 30%;
}

.canDoTitle {
  height: 3rem;
  margin-bottom: 1rem;
}

.canDoCaption {
  margin-bottom: 2.5rem;
  height: 15rem;
}

.introBox,
.canDoBox,
.aboutBox {
  margin-bottom: 6rem;
  text-align: center;
  opacity: 0;
}

.introBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*タブレット*/
@media (max-width: 850px) and (min-width: 551px) {
  .canDoTitle {
    margin-bottom: 3rem;
  }
  .logoMob {
    width: 200px;
  }
}
@media (max-width: 1180px) and (min-width: 551px) {
  .brCreatePlan::after {
    content: "\A";
    white-space: pre;
  }
  .brParticipatePlan::after {
    content: "\A";
    white-space: pre;
  }
  .brRecordPlan::after {
    content: "\A";
    white-space: pre;
  }
  .canDoTitle {
    margin-bottom: 3rem;
  }
}

@media (max-width: 400px) {
  .brAbout::after {
    content: "\A";
    white-space: pre;
  }
}
@media (max-width: 900px) {
  .canDoCaption {
    margin-bottom: 2.5rem;
    height: 20rem;
  }
}

/*モバイル*/
@media (max-width: 550px) {
  .introBox,
  .canDoBox,
  .aboutBox {
    margin-bottom: 4rem;
    text-align: center;
    opacity: 0;
  }
  .subTitle {
    margin-bottom: 1rem;
  }

  .canDoList {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }

  .canDoTitle {
    height: 2.5rem;
  }

  .canDoContent {
    width: 100%;
    margin-top: 3rem;
  }

  .canDoCaption {
    margin-bottom: 2.5rem;
    height: auto;
  }
}
.planExampleBox {
  margin-top: 6rem;
  margin-bottom: 6rem;
  text-align: center;
  opacity: 0;
}

.contactBox {
  margin-top: 6rem;
  margin-bottom: 6rem;
  text-align: center;
  opacity: 0;
  width: 80%;
}

.communityImage {
  width: 60%;
  height: 10%;
}

.cardSkin {
  border: solid 1px var(--gray-color);
  margin: 0 20px;
  width: 30%;
  overflow: hidden;
  border-radius: 8px;
}
.cardSkin img {
  width: 100%;
  height: auto;
}
.cardsBox {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.cardTitle {
  margin: 2rem 1rem 1rem 1rem;
  font-weight: 600;
  text-align: left;
  font-size: 1.2rem;
  line-height: 2.4rem;
}

.canDoContent a {
  text-decoration: none;
}

.planDetail {
  text-align: left;
  margin-left: 1rem;
}

.date,
.place {
  margin-bottom: 0.6rem;
  font-size: 1rem;
}

.tag {
  padding: 2px 10px;
  display: inline-block;
  width: auto;
  border: none;
  background-color: var(--gray-color);
  border-radius: 15px;
  margin-top: 0.2rem;
}

.faHeart {
  font-size: 1.5rem;
  color: var(--gray-color);
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.goodButton {
  color: var(--gray-color);
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.pink {
  color: #e60039;
}

.goodButtonBox {
  margin: 0.5rem 1rem 1rem 0;
  display: flex;
  justify-content: flex-end;
}

.host img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: solid 1px var(--gray-color);
}

/* タブレット */
@media (max-width: 1050px) {
  #defaultButton {
    display: block;
    margin: 0 auto;
    font-weight: 600;
    font-size: 0.9rem;
    border: none;
    background-color: var(--primary-color);
    color: #ffffff;
    border-radius: 15px;
    padding: 0.6rem 1rem;
    text-decoration: none;
  }
}

/* モバイル */
@media (max-width: 500px) {
  .cardSkin {
    width: 85%;
    margin: 10px auto;
  }
  .cardsBox {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  #cardsMobile {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .planExampleBox {
    margin: 0 auto;
    text-align: center;
    /* opacity: 0; */
  }
}
