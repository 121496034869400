.pageTitle {
  text-align: left;
}
.forms {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.stickyProgressBarBox {
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progressHeaderBox {
  position: fixed;
  top: 0;
  left: 0;
  height: 65px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1; /* inputScheduleBoxと同じ */
}

.stickyProgressBarBox {
  position: sticky;
  width: 80%;
  top: 3rem;
  margin: 0 auto;
}

/* タブレット＆モバイル表示 */
@media (max-width: 768px) {
  .progressBarBox {
    bottom: 1rem;
  }
}

#bottomDisclosureCheckBox label {
  display: inline-block;
  padding: 0.2rem 1rem;
  margin: 0.2rem;
  font-size: 1.1rem;
}

#bottomDisclosureCheckBox input {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.progressBarBox {
  width: 70%;
  background-color: var(--green-gray);
  align-items: center;
  border-radius: 40px;
  z-index: 1;
  padding-top: 0.5rem;
  border: solid 0.5px var(--gray-color);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  bottom: 1rem;
}

.progressBarBox p {
  font-size: 0.8rem;
  font-weight: 600;
  color: black;
}

.progressForwardButtonBox {
  display: flex;
  text-align: center;
}

.progressHeader {
  width: 100%;
  height: 65px;
  min-height: 65px;
  background-color: #ffffff;
  border-bottom: solid 1.5px var(--gray-color);
  white-space: pre;
  padding: 0.5rem 2%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.tempSaveButton,
.tempSaveDone,
.tempSaveLoading,
.tempSaveError {
  width: 140px;
  margin-right: 5px;
}
.faCheck {
  margin-right: 5px;
  color: var(--primary-color);
}
.tempSaveDone {
  padding: 0.7rem 1.7rem;
  font-weight: 600;
  border: none;
  color: #111111;
  border-radius: 25px;
}

.tempSaveError {
  font-weight: 600;
  padding: 0.7rem 1.7rem;
  color: var(--red);
}

.tempSaveLoading {
  border: none;
  background-color: var(--gray-color);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.forwardButton {
  color: black;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

/* モバイル表示 */
@media (max-width: 719px) {
  .progressBarBox {
    width: 95%;
    margin-left: 0;
    display: flex;
  }

  .forwardButtonMobile,
  .submitButtonMobile,
  .tempSaveDone,
  .tempSaveButton,
  .tempSaveError {
    width: 115px;
    font-size: 0.8rem;
    display: block;
    border-radius: 25px;
  }
  .tempSaveDone {
    padding: 0.5rem 0.4rem;
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 20px;
  }
  .tempSaveError {
    padding: 0.5rem 1rem;
  }
  .tempSaveLoading {
    width: 115px;
  }
}

@media (max-width: 450px) {
  #bottomDisclosureCheckBox {
    text-align: center;
    width: 100%;
  }
  #bottomDisclosureCheckBox label {
    font-size: 0.8rem;
  }
  #bottomDisclosureCheckBox input {
    width: 0.3rem;
    height: 0.3rem;
  }
  .progressHeader,
  .bottomInfoBox {
    height: 95px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.rightAllow {
  color: var(--primary-color);
  font-size: 2rem;
}

.step {
  margin: 0;
  font-weight: 600;
}

.numberCircle {
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background-color: var(--primary-color);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.inputBasicInfoBox {
  /* position: relative; */
  margin: 0 auto;
  width: 70%;
  margin-top: 70px;
}

.inputOthersBox {
  margin: 0 auto;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.progressBackButton {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.progressNavTitle {
  cursor: pointer;
  display: flex;
  margin: 0 1rem;
  flex-direction: column;
  align-items: center;
}

.progressNavTitle p {
  font-size: 0.8rem;
  font-weight: 500;
  text-align: left;
  margin: 3px;
}

.progressBackButton p {
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  margin: 3px;
}

/* for mobile */
@media (max-width: 500px) {
  .numberCircle {
    display: flex;
    font-size: 0.7rem;
    color: #ffffff;
    background-color: var(--primary-color);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .progressNavTitle {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin: 0 0.3rem;
  }
  .progressBackButton p {
    font-size: 0.8rem;
    text-align: left;
    margin: 2px 0;
  }
}
.inputCaption {
  margin: 0;
  font-size: 0.8rem;
  text-align: left;
  margin-bottom: 0.4rem;
}

.asterisk {
  font-size: 0.6rem;
  color: var(--red);
}

.subtitleBox {
  /* margin: 20px auto; */
  text-align: left;
  margin-bottom: 1rem;
}
/* h2  */
.subtitle {
  display: flex;
  text-align: left;
  color: var(--primary-color);
  border-bottom: dashed 2px var(--primary-color);
  padding-bottom: 0.6rem;
  white-space: pre;
  align-items: center;
}

/* h3 */
.inputTitle {
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  color: #111111;
  display: flex;
  align-items: center;
}

/* h4 */
.inputSubTitle {
  text-align: left;
  margin: 0.6rem 0;
  color: #111111;
  display: flex;
  align-items: center;
}

/* h4 */
.inputSubTitle {
  margin-bottom: 0.4rem;
}

/*追加情報ラベル */
.toggleFormLabel {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  background-color: var(--green-gray);
  justify-content: space-between;
}

.originalPlan {
  margin: 10px 0;
  font-size: 0.8rem;
}

.toggleFormLabel button {
  width: 100%;
}
.toggleIcon {
  margin-right: 0.5rem;
  font-size: 1.8rem;
  transition: transform 0.3s ease; /* アイコンの角度を変更する際のトランジション */
  color: #111111;
}

.formContainer {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease, opacity 0.2s ease; /* 高さと不透明度を変更する際のトランジション */
}
.deleteURLButton {
  color: #111111;
}

@media (max-width: 500px) {
  .subtitleBox {
    margin-bottom: 0.5rem;
  }
  .inputTitle {
    margin-top: 0.6rem;
  }
}
.purposeCategory {
  color: #111111;
  margin-bottom: 0.5rem;
}

.planName input,
.planDescription textarea,
.prefecture input,
.city input,
.date input,
.deadline input,
.maxParticipants input,
.minParticipants input,
.belongings input,
.price input,
.applyMethods input,
.userRideLevel textarea,
.notes textarea {
  width: 100%;
  line-height: 1.7;
}

.forms textarea {
  height: 100px;
  max-width: 100%;
  height: auto;
  min-width: 100%;
}

.date input,
.deadline input {
  -webkit-appearance: none;
}

.planName input {
  font-size: 1rem;
}

.planName input::placeholder {
  font-size: 1rem;
}

.planDescription textarea {
  height: 15rem;
}

.applyMethodBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  align-items: flex-start;
}

.applyMethodContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.applyMethodContent button {
  width: 2rem;
  height: 2rem;
}

.addInputPlus {
  display: flex;
  font-size: 1.5rem;
  flex-direction: row;
  align-items: center;
  height: auto;
  width: auto;
}

.addInputPlus p {
  margin: 0;
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

/* img upload */
.uploadImageBox {
  width: 40vw;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.topImageTitleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.uploadTest {
  margin: 2rem;
}

.uploadImg {
  width: 70%;
  height: auto;
}

.uploadLabel input {
  display: none;
}

.closeBtn {
  color: #111111;
  border: none;
  font-size: 2rem;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px;
  visibility: hidden;
  height: 50px;
}

.topImgBox {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 40vw;
  height: calc(40vw * 0.75);
  object-fit: cover;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
}

@media (max-width: 500px) {
  .topImgBox {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 90vw;
    height: calc(90vw * 0.75);
    object-fit: cover;
    margin-bottom: 2rem;
    margin-top: 0.1rem;
  }
}

.topImgBox img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fileUpload {
  width: 100%;
  height: 100%;
  background: url(../img/noimage.png) center center / contain no-repeat;
  display: block;
  cursor: pointer;
}

.fileUpload::after {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fileUpload:hover::after {
  width: 100%;
  height: 100%;
  opacity: 0.05;
  background-color: #111111;
}

.fileUpload input {
  display: none;
}
.uploadImageButton {
  cursor: pointer;
  display: block;
}

.angleRight,
.angleLeft {
  filter: drop-shadow(2px 2px 1px #1b1b1b);
}

/* モバイル・タブレット */
@media (max-width: 800px) {
  .uploadImageBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .closeBtn {
    font-size: 1.5rem;
  }
}

@media (max-width: 500px) {
  .closeBtn {
    font-size: 1.5rem;
  }
}

.faPlus {
  margin-left: 0.4rem;
}

.uploadButtonBox p {
  margin: 0;
  margin-top: 1.5rem;
  font-size: 0.8rem;
}

.uploadButtonBox input {
  display: none;
}

.uploadButton {
  margin-top: 4rem;
}

.pottering,
.training {
  margin-bottom: 1rem;
}

.rideLevel,
.ridePurpose,
.bicycleType {
  margin: 0;
  text-align: left;
}

.checkBox {
  display: flex;
  margin: 1rem 0;
  flex-wrap: wrap;
}

.checkBox label {
  margin-right: 2rem;
  padding: 1rem;
}
.checkBox input {
  vertical-align: middle;
  margin-right: 0.5rem;
}

/* for mobile and tablet */
@media (max-width: 700px) {
  .inputBasicInfoBox,
  .inputOthersBox {
    width: 90%;
  }

  .checkBox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* overflow: scroll; */
    margin: 1rem 0;
  }

  .checkBox label {
    display: inline-block;
    margin: 0.1rem;
    padding: 0.5rem;
  }

  #disclosureCheckBox {
    width: 100%;
    display: flex;
    margin: 0.5rem 0;
    justify-content: flex-start;
  }

  .regionSearchBox select {
    margin-bottom: 1rem;
  }
}

.planName input,
.date input,
.deadline input,
.maxParticipants input,
.minParticipants input,
.price input {
  background: #ffffff;
  line-height: 1.7;
}

.maxParticipants input,
.minParticipants input,
.estimate input {
  width: 100px;
}

.maxParticipants,
.minParticipants,
.estimate {
  text-align: left;
}

@media (max-width: 400px) {
  .planName input::placeholder {
    font-size: 0.8rem;
  }
  .notes input::placeholder {
    font-size: 0.8rem;
  }
}
