.cardBox {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  width: 25vw;
  min-width: 25vw;
  border-radius: 8px;
  color: black;
  text-decoration: none;
}

.motionSkin {
  position: relative;
  width: 100%;
}

.cardLink {
  position: absolute;
  top: 0;
  background: transparent;
  border-radius: 8px;
  border: none;
  display: block;
  height: 100%;

  width: 100%;
  outline: none;
}
.cardBox:hover {
  cursor: pointer;
}

.cardBox img {
  display: block;
  max-width: 100%;
  width: 25vw;
  height: calc(25vw * 0.75);
  object-fit: cover;
  overflow: hidden;
  border-radius: 8px;
}

.imageBox {
  position: relative;
  width: 100%;
}

.planImage {
  background-color: #f5f5f5;
}

.disclosureRange {
  position: absolute;
  bottom: 5%;
  right: 8%;
  font-size: 0.7rem;
  padding: 1px 10px;
  display: inline-block;
  width: auto;
  border: none;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border-radius: 10px;
  letter-spacing: 2px;
  line-height: 1.7;
}
.disclosureRange p {
  margin: 0;
}

/* プランカードのメニューボタン */
/* open */
.menuOpenBtnBox {
  position: absolute;
  top: 3%;
  right: 5%;
  z-index: 1;
}
.ellipsisBtnCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: var(--gray-color);
  border-radius: 50%;
}
/* close */
.menuCloseBtnBox {
  position: absolute;
  top: -3%;
  right: -5%;
  z-index: 1;
}
.closeBtnCircle {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  background-color: var(--gray-color);
  border-radius: 50%;
}

.faEllipsis,
.faTimes {
  color: black;
  font-size: 1.3rem;
  width: 25px;
  height: 25px;
}

/* メニュー内 */
.menuIcon {
  font-size: 0.8rem;
  margin-right: 5px;
}

.planCardMenu {
  width: 150px;
  position: absolute;
  top: 0;
  right: 5%;
  z-index: 1;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.planCardMenuList {
  position: relative;
  margin: 0;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.menuButtonBox {
  width: 100%;
  margin: 0.6rem 0;
  font-size: 0.8rem;
  color: #111;
  display: flex;
  cursor: pointer;
  align-items: center;
}

/* カードの中身 */
.cardInfo {
  margin-top: 0.2rem;
  padding-bottom: 1rem;
  height: 250px;
}

/* for tablet */
@media (max-width: 1300px) {
  .cardBox {
    width: 25vw;
    min-width: 25vw;
  }

  .cardBox img {
    width: 25vw;
    height: calc(25vw * 0.75);
  }
}

/* for mobile */
@media (max-width: 500px) {
  .cardBox {
    width: 85vw;
    min-width: 85vw;
  }

  .cardBox img {
    width: 90vw;
    height: calc(90vw * 0.8);
  }
}

.cardTitle {
  max-height: 80px;
  margin-top: 1rem;
  margin-bottom: 0.3rem;
  font-weight: 600;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.planDetail {
  text-align: left;
  margin-left: 0.2rem;
}

.tag {
  font-size: 0.8rem;
  padding: 1px 10px;
  display: inline-block;
  width: auto;
  border: none;
  background-color: var(--gray-color);
  border-radius: 15px;
  margin-bottom: 0.2rem;
  margin-right: 5px;
  line-height: 1.7;
  letter-spacing: 1px;
}

.place,
.tagBox {
  margin-bottom: 0.3rem;
  font-size: 1rem;
}

.bottomRow {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.emptyDiv {
  height: 30px;
}
.creator {
  /* border-bottom: 0.8px solid var(--gray-color); */
  position: absolute;
  top: 3%;
  right: 5%;
  text-align: left;
  z-index: 1;
  width: 90%;
  height: 30px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 0.8rem;
  color: #111;
}
.date {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 0.5rem;
}

.creationlabel {
  font-size: 0.8rem;
  display: flex;
  margin: 5px 0;
  flex-direction: column;
  align-items: center;
}

.creationinfo {
  font-size: 0.8rem;
  line-height: 1.7;
  color: #111;
}

/* for mobile */
@media (max-width: 500px) {
  .faHeart {
    font-size: 1.5rem;
  }

  .place,
  .tagBox {
    margin-bottom: 0.4rem;
  }
}
