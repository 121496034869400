.overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
}

.modalContet {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 10px;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  z-index: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonBox {
  display: flex;
  justify-content: space-around;
  width: 60%;
  margin-top: 20px;
}

@media (max-width: 650px) {
  .modalContet {
    width: 80%;
  }
}
