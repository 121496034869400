.planCardsBox {
}

/*-----プランリストの全表示-----*/
.cardsBoxWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 1rem auto;
  max-width: 95%;
}

/* -----プランリストのスライダー-----  */
.planCardsSliderPC {
  width: 100%;
  position: relative;
  margin: 1rem 1rem;
  min-height: calc(40vw * 0.75);
  height: auto;
}
.cardsBoxSlider {
  display: none;
}
/* for mobile and tablet */
@media (max-width: 800px) {
  .planCardsSliderPC {
    display: none;
  }
  .cardsBoxSlider {
    display: flex;
    max-width: 95%;
    flex-direction: row;
    overflow-x: scroll;
    flex-wrap: nowrap;
    margin: 1rem 0;
    padding: 5px 0;
    gap: 1rem;
  }
}
.fetchConditionBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2% 0;
}
.conditionTextBox {
  margin: 0 auto;
  display: grid;
  font-weight: 600;
}

/* for mobile */
@media (max-width: 730px) {
  .fetchConditionBox {
    display: flex;
    padding: 10% 0;
  }

  .conditionTextBox {
    margin: 0 auto;
    display: grid;
    font-weight: 600;
  }

  .conditionTextBox div {
    margin: 0 auto;
    margin: 1rem;
    text-align: center;
  }
}
