.App {
  max-width: 3000px;
  margin: 0 auto;
  overflow: hidden;
}
#container {
  min-height: 80vh;
}
/*calcurate the font-size in this URL https://websemantics.uk/tools/responsive-font-calculator/*
/* global font size */
/*from 15 to 16px*/
@media (min-width: 200px) {
  .App {
    font-size: calc(0.9375rem + ((1vw - 2px) * 0.0435));
    min-height: 0vw;
  }
}
@media (min-width: 2500px) {
  .App {
    font-size: 16px;
  }
}

/* from 16 to 18 px */
@media (min-width: 200px) {
  #pageTitle {
    margin-top: 0.8rem;
    margin-left: 1rem;

    font-size: calc(1rem + ((1vw - 3px) * 0.2222));
    min-height: 0vw;
    text-align: left;
  }
}
@media (min-width: 1200px) {
  #pageTitle {
    margin: 2rem;
    font-size: 18px;
  }
}

/*from 24 to 27 px */
@media (min-width: 200px) {
  h1 {
    font-size: calc(1.5rem + ((1vw - 2px) * 0.1304));
    min-height: 0vw;
    margin: 0;
  }
}
@media (min-width: 2500px) {
  h1 {
    font-size: 27px;
  }
}

/*from 23 to 25 px */
@media (min-width: 200px) {
  h2 {
    font-size: calc(1.4375rem + ((1vw - 2px) * 0.1304));
    min-height: 0vw;
    margin: 0;
  }
}
@media (min-width: 2500px) {
  h2 {
    font-size: 26px;
  }
}
/*from 20 to 23 px */
@media (min-width: 200px) {
  h3 {
    font-size: calc(1.25rem + ((1vw - 2px) * 0.1304));
    min-height: 0vw;
    margin: 0;
  }
}
@media (min-width: 2500px) {
  h3 {
    font-size: 23px;
  }
}
/* from 17 to 19 px */
@media (min-width: 200px) {
  h4 {
    font-size: calc(1.0625rem + ((1vw - 2px) * 0.087));
    min-height: 0vw;
    margin: 0;
  }
}
@media (min-width: 2500px) {
  h4 {
    font-size: 19px;
  }
}
:root {
  --primary-color: #009c74;
  --primary-dark-color: #007058;
  --blue-button-color: #46b2e7;
  --red: #f82b2b;
  --blue:#3c91e6;
  --gray-color: #cccccc;
  --light-gray: #f5f5f5;
  --green-gray: #e8fdf4;
  --light-blue: #e6f7ff;
}
[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: var(--primary-color);
  --amplify-components-button-primary-active-background-color: var(--primary-color);
  --amplify-components-button-primary-visited-background-color: var(--primary-color);
  --amplify-components-button-primary-focus-background-color: var(--primary-color);
  --amplify-components-button-primary-hover-background-color: var(--primary-dark-color);
  --amplify-components-button-link-color: var(--primary-color);
  --amplify-components-button-link-hover-color: var(--primary-color);
  --amplify-components-button-link-focus-color: var(--primary-color);
  --amplify-components-button-link-active-color: #f5f5f5;
  --amplify-components-button-link-hover-background-color: #f5f5f5;
  --amplify-components-button-link-active-background-color: var(--primary-dark-color);
  --amplify-components-tabs-item-active-color: var(--primary-color);
  --amplify-components-tabs-item-color: #c7c7c7;
  --amplify-components-tabs-item-hover-color: var(--primary-color);
  --amplify-tabs-item-active-color: var(--primary-color);
  --amplify-components-tabs-item-focus-color: var(--primary-color);
  --amplify-components-tabs-item-active-border-color: var(--primary-color);
}

textarea,
input {
  box-sizing: border-box;
  margin: 0;
  padding: 1rem 0.8rem;
  border: solid 1px var(--gray-color);
  border-radius: 5px;
}
