.searchBox {
  position: relative;
  justify-content: space-between;
}
.searchBox input {
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  outline: none;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0.5rem 0.5rem;
  border: solid 1px var(--gray-color);
  border-radius: 5px;
  line-height: 2;
}

.closeBtn {
  position: absolute;
  top: 8%;
  right: 1%;
  border: none;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1.3rem;
  text-align: center;
  color: #111111;
}
@media (max-width: 500px) {
  .closeBtn {
    right: 1%;
  }
}
