/* header */
.headerSpace {
  position: relative;
  width: 100%;
  height: 125px;
}
.header {
  position: absolute;
  transition: transform 0.45s cubic-bezier(0.23, 1, 0.58, 1), -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.58, 1);
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  border-bottom: 1px solid var(--gray-color);
  padding: 1.2rem 1.5rem 0 1.5rem;
}

.isFixed {
  position: fixed;
}

.hiddenHeader {
  transform: translateY(-100%);
}

/* モバイル */
@media (max-width: 750px) {
  .header {
    display: flex;
    padding: 0.3rem 1rem;
  }
  .headerSpace {
    height: 52px;
  }
  .header h2 {
    display: none;
  }
  .logoBox {
    display: none;
  }
}
/* タブレット/PC */
@media (min-width: 751px) {
  .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .LogoMob {
    display: none;
  }

  .logoBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logoBox p {
    margin: 0;
    font-size: 1rem;
    font-weight: 550;
    margin-left: 1rem;
  }

  .logoPC {
    width: 165px;
    height: auto;
  }
}

.headerBox a {
  text-decoration: none;
}

.headerTitle {
  cursor: pointer;
  display: flex;
  text-align: center;
  align-items: center;
}

.authBox {
  display: block;
}

.mobileAuthBox {
  display: none;
}

/* menu */
.nav {
  display: none;
}

.menuBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 1%;
  padding: 0;
  height: 43px;
}

.menuBox ul {
  padding: 0;
  list-style: none;
}

.menuBox button {
  font-weight: 600;
  color: #000000;
  padding: 0.6rem;
}

.menuBox a {
  font-weight: 600;
  text-decoration: none;
  color: #000000;
}
.disabledMenu {
  font-weight: 600;
  margin-right: 2rem;
  text-decoration: none;
  color: var(--gray-color);
}

.disabledMenu p {
  margin: 0 auto;
  padding: 0.6rem 0.6rem 0 0.6rem;
}

.captionBox {
  opacity: 0;
  transition: 0.5s;
}

.caption {
  font-size: 0.5rem;
  text-align: center;
  color: var(--red);
  transition: 1s;
}

.disabledMenu:hover .captionBox {
  opacity: 1;
}

.menuBar1 {
  display: flex;
  font-size: 1.3rem;
  margin: 0;
  align-items: flex-start;
}

.menuBar1 li {
  margin-right: 2rem;
}

.menuBar2 {
  font-size: 1rem;
}

/* タブレット */
@media (max-width: 1050px) {
  .menuBox1 li,
  .menuBox div {
    margin-right: 1.5rem;
  }
}
@media (max-width: 850px) {
  .menuBar1 li {
    font-size: 1.2rem;
  }
  .menuBar2 li {
    font-size: 0.9rem;
  }
}

.buttonBox {
  display: flex;
  justify-content: space-around;
  width: 18%;
  height: 2rem;
}

/* ボタン */
#mypageButton,
#logoutButton {
  font-weight: 600;
  border: none;
  color: #000000;
  background-color: rgba(0, 0, 0, 0);
}

button {
  cursor: pointer;
}

/*モバイル */
@media (max-width: 750px) {
  body {
    width: 100%;
  }
  .menuBox {
    display: none;
  }
  .authBox {
    display: none;
  }

  .headerBox {
    width: 100%;
    z-index: 3;
    display: flex;
    height: 42px;
  }

  .hamburgerMenu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100%;

    z-index: 1;
    overflow: auto;
    overflow: scroll;
  }

  .nav {
    display: flex;
    justify-content: flex-end;
  }

  .drawerHidden {
    display: none;
  }

  .drawerOpen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    cursor: pointer;
  }

  .faBars {
    position: absolute;
    right: 1rem;
    font-size: 1.6rem;
  }

  .faTimes {
    position: fixed;
    visibility: hidden;
    right: 1.7rem;
    background-color: var(--light-gray);
    border-radius: 50%;
    width: 2.2rem;
    height: 2.2rem;
  }
  .mobileAuthBox {
    display: block;
    position: fixed;
    width: 100%;
    top: 7.5%;
    left: 100%;
    opacity: 0;
    z-index: -1;
    transition: 0.5s;
  }
  .nav input:checked ~ .mobileAuthBox {
    opacity: 1;
    left: 18%;
    z-index: 1;
    padding-inline-start: 1rem;
  }

  .navContent,
  .navOverlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 100%;
    background: #fff;
    transition: 0.5s;
    z-index: 0;
  }

  .navOverlay {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .nav input:checked ~ .navContent {
    left: 18%;
  }
  .nav input:checked ~ .navOverlay {
    left: 0%;
  }

  .navList {
    list-style: none;
    margin-top: 9rem;
    padding-inline-start: 2rem;
  }

  .navList button {
    text-decoration: none;
    color: #000000;
    text-align: left;
    font-weight: 600;
    padding: 10px 0;
    padding-right: 55px;
  }

  .captionBox {
    display: inline-block;
    opacity: 0;
  }

  .caption {
    margin-left: 1rem;
    font-size: 0.5rem;
  }
  .disabledMenuItem {
    width: 80%;
  }
  .disabledMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .disabledMenu:hover .captionBox {
    opacity: 1;
  }

  .navItem {
    margin-top: 2rem;
    width: 75%;
  }
}

@media (max-width: 550px) {
  .navList {
    padding-inline-start: 1rem;
  }
}
