.mainTitle {
  display: flex;
}
/* filterbutton */
.filterButton {
  width: 40%;
  border: none;
  background-color: #dddddd;
  color: #111111;
  border-radius: 20px;
  padding: 0.7rem 1rem;
  font-weight: 600;
  margin: 2rem 0;
}
/* for PC */
.filterButton {
  display: none;
}

.notShowFilterButton {
  display: none;
}

/* for mobile */
@media (max-width: 500px) {
  .filterButton {
    display: block;
    width: 40%;
  }
}

.message {
  text-align: center;
  color: var(--primary-color);
  margin-top: 10px;
  margin-bottom: 30px;
}

@media (max-width: 500px) {
  .message {
    margin-bottom: 10px;
  }
}

.planBox {
  position: relative;
  display: flex;
  margin: 10px 0px;
  width: 100%;
  min-height: 200px;
  flex-direction: column;
}

.planListBox {
  margin-top: 10px;
  margin-bottom: 10px;
}
.listTitle {
  left: 0%;
  margin: 0px 30px;
}

.listCaption {
  margin: 10px 35px;
}
/* for mobile */
@media (max-width: 500px) {
  .listTitleBox {
    text-align: center;
  }
}
.map {
  width: 100%;
  height: 100%;
  position: relative;
}
.faPlus {
  font-size: 3rem;
}

.fixedPlusButton {
  text-align: center;
  width: 70px;
  height: 70px;
  position: fixed;
  bottom: 7%;
  right: 4%;
  color: #ffffff;
  border-radius: 50%;
  background-color: var(--blue-button-color);
  border: none;
  z-index: 1
}

/* for Tablet */
@media (max-width: 980px) {
  .planBox {
    /* width: 90%; */
    margin: 0 auto;
  }

  .findPlanTitle {
    margin: 0;
  }

  .fixedPlusButton {
    width: 50px;
    height: 50px;
    bottom: 10%;
    right: 10%;
  }

  .faPlus {
    font-size: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }

  .filterButton {
    display: block;
    padding: 0.4rem 1.8rem;
  }
}
