.editNickname {
  text-align: right;
  width: 80%;
}
.usernameInput {
  width: 100%;
}
.buttonBox {
  display: flex;
  justify-content: center;
}

