/* global button style */
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}
button,
li {
  width: auto;
  transition: all 0.1s ease-in-out;
}

button:hover,
li:hover {
  opacity: 0.6;
  cursor: pointer;
}
button:active,
li:active {
  scale: 0.95;
}

@media (max-width: 500px) {
  button:hover,
  li:hover {
    opacity: 1;
  }
}

/* defaultButton  基本のボタンCSS  */
#defaultButton {
  padding: 0.7rem 1.7rem;
  font-weight: 600;
  border: none;
  background-color: var(--primary-color);
  color: #ffffff;
  border-radius: 25px;
}
#grayButton {
  padding: 0.7rem 1.7rem;
  font-weight: 600;
  border: none;
  background-color: var(--gray-color);
  color: #111111;
  border-radius: 25px;
}

#smallGreenButton {
  padding: 0.5rem 1rem;
  font-weight: 600;
  border: none;
  background-color: var(--primary-color);
  color: #ffffff;
  border-radius: 15px;
  margin-top: 5px;
  margin-right: 5px;
}

#smallGrayButton {
  height: 2.5rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  border: none;
  background-color: var(--gray-color);
  color: #111111;
  border-radius: 15px;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 5px;
}

/* ファイル、スケジュールの追加ボタン */
#addBlueButton {
  border: none;
  background-color: var(--blue-button-color);
  color: #ffffff;
  border-radius: 25px;
  padding: 0.7rem 2rem;
  font-weight: 600;
  cursor: pointer;
}

@media (max-width: 1050px) {
  #smallGrayButton {
    height: 2rem;
    font-size: 0.7rem;
    padding: 0.2rem 0.7rem;
  }
  #defaultButton,
  #grayButton,
  #addBlueButton {
    padding: 0.6rem 0.6rem;
  }
}

@media (max-width: 500px) {
  #defaultButton,
  #grayButton,
  #addBlueButton {
    padding: 0.5rem 0.6rem;
    width: auto;
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 20px;
  }
}
/* top pobicleでできることbutton */
#canDoListButton {
  font-size: 1rem;
  padding: 1rem 3rem;
  font-weight: 600;
  border: none;
  background-color: var(--primary-color);
  color: #ffffff;
  border-radius: 25px;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
}

@media (max-width: 1050px) {
  #canDoListButton {
    font-size: 0.8rem;
    padding: 0.8rem;
  }
}

@media (max-width: 551px) {
  #canDoListButton {
    width: 70%;
  }
}

.faAngle {
  margin-left: 0.2rem;
  font-size: 1.2rem;
}
@media (max-width: 500px) {
  .faAngle {
    font-size: 0.9rem;
  }
}

/*インフォカードのアクションボタン */
#actionButtonIcon {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.7rem;
}

#spotCardButtonName {
  font-size: 0.5rem;
  text-align: center;
  width: 9rem;
  margin-top: -1rem;
}

#actionButtonIconCircle {
  width: 3rem;
  height: 3rem;
  border: none;
  border-radius: 50%;
  background-color: rgb(156, 153, 153);
  margin-left: 3rem;
}

#buttonArrangement {
  padding: 1rem;
  min-width: 9.5rem;
}
