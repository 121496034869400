.hideCheckbox {
  display: none;
}
.buttonPadding {
}
.faTimesRight {
  position: absolute;
  text-align: right;
  right: 7%;
  top: 0.5rem;
  background-color: #dddddd;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.4rem 0.4rem;
  z-index: 1;
}
.faTimesLeft {
  position: absolute;
  text-align: Left;
  left: 5%;
  top: 1.4rem;
  background-color: #dddddd;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.4rem 0.4rem;
  z-index: 1;
}

.faTimes:hover {
  cursor: pointer;
}
