.mySwiper {
  --swiper-theme-color: linear-gradient(
    90deg,
    rgba(51, 238, 172, 1) 27%,
    rgba(5, 192, 143, 1) 83%,
    rgba(7, 189, 142, 1) 100%
  );
}

swiper-container::part(button-prev) {
  color: var(--primary-color);
  padding: 1rem;
  padding-right: 2rem;
  transform: translate(0px, -2rem);
  background-color: whitesmoke;
  border-radius: 50%;
  opacity: 0.85;
}

swiper-container::part(button-next) {
  color: var(--primary-color);
  padding: 1rem;
  padding-left: 2rem;
  transform: translate(0px, -2rem);
  background-color: whitesmoke;
  border-radius: 50%;
  opacity: 0.85;
}

swiper-container::part(button-prev):hover,
swiper-container::part(button-next):hover {
  opacity: 0.7;
}

/* -----トップ画像のスライダー-----  */
.topImgBoxPC,
.topImgBoxMobile {
  width: 100%;
  position: relative;
  margin: 1rem 0;
  min-height: calc(40vw * 0.75);
}

.topImgBoxMobile {
  display: none;
}

@media (max-width: 500px) {
  .topImgBoxPC {
    display: none;
  }
  .topImgBoxMobile {
    display: block;
  }
  .slideBox:hover {
    transform: none;
  }
  swiper-container::part(button-prev) {
    padding: 0.8rem;
    padding-right: 1.5rem;
    background-color: transparent;
  }

  swiper-container::part(button-next) {
    padding: 0.8rem;
    padding-left: 1.5rem;
    background-color: transparent;
  }
}

.slideBox {
  display: flex;
  max-width: 100%;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: 1s all;
}
.slideBox img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.slideBox::after {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  transition-duration: 0.3s;
  transition-property: background-color, opacity;
  transition-timing-function: ease-out;
  background-image: linear-gradient(180deg, transparent 0% 60%, rgb(0, 0, 0));
}

.scheduleImg {
  cursor: grab;
}
.scheduleImg :active {
  cursor: grabbing;
}

.topImgBoxMobile img {
  width: 100%;
  padding-top: 1px;
  max-width: 100vw;
  height: calc(100vw * 0.75);
  max-height: calc(100vw * 0.75);
  object-fit: cover;
}

.topImgBoxPC img {
  width: 100%;
  max-width: 100%;
  height: calc(50vw * 0.75);
  max-height: calc(50vw * 0.75);
  object-fit: cover;
}

.topImgTitle {
  position: absolute;
  font-size: 1.3rem;
  color: #ffffff;
  padding: 0 2rem;
  text-align: center;
  top: 80%;
  left: 0;
  z-index: 1;
  text-overflow: ellipsis;
}

@media (max-width: 500px) {
  .topImgTitle {
    font-size: 1.2rem;
    top: 75%;
  }
}

.titleBox {
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
