.authButtonBox {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.loginUser {
  display: flex;
  align-items: flex-end;
  /* margin-right: 10px; */
  flex-direction: column;
}

.loginButton {
  border: none;
  background-color: #dddddd;
  color: #111111;
  border-radius: 20px;
  padding: 0.5em 2em;
  font-size: 0.8rem;
  margin-right: 0.6rem;
  font-weight: 900;
  white-space: nowrap;
}

.greenButton {
  border: none;
  background-color: var(--primary-color);
  color: #ffffff;
  border-radius: 20px;
  padding: 0.5em 2em;
  font-size: 0.8rem;
  font-weight: 700;
  white-space: nowrap;
}

.logoutButton {
  color: #000000;
  margin-right: 1rem;
}

/* タブレット */
@media (max-width: 1050px) {
  .authButtonBox {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (max-width: 750px) {
  .logoutButton {
    padding: 4px 0;
  }
}

.usernameBox {
  margin-right: 15px;
  display: flex;
}

.handleUserStatusButton {
  width: 100%;
  display: flex;
  border: none;
  color: #000000;
  background-color: rgba(0, 0, 0, 0);
  font-size: 0.85rem;
  white-space: nowrap;
  padding: 0;
  align-content: flex-end;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.greeting,
.username {
  white-space: nowrap;
}

.faPen {
  margin-left: 4px;
  font-size: 0.85rem;
}

.faPen:hover {
  cursor: pointer;
}

/*モバイル */
@media (max-width: 750px) {
  .greeting,
  .username {
    white-space: normal;
  }

  .handleUserStatusButton {
    white-space: nowrap;
    justify-content: space-around;
    white-space: normal;
    font-size: 0.8rem;
    margin-top: 0.3rem;
  }

  .usernameBox {
    margin-right: 3px;
    display: flex;
    flex-direction: column;
  }

  .loginUser {
    max-width: 90%;
    position: absolute;
    display: flex;
    margin-bottom: 0.5rem;
    align-items: flex-start;
  }
  .authButtonBox {
    margin-top: 0.5rem;
    margin-left: 1rem;
    justify-content: left;
  }

  .loginButton,
  .greenButton {
    font-weight: 600;
    padding: 0.5rem 1rem;
  }

  .faPen {
    font-size: 0.85rem;
  }
}
