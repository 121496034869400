.card {
  width: 100%;
}

.backButton {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  font-weight: 600;
}

.infoContainer {
  width: 100%;
  height: 100%;
  padding: 0.8rem 1rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  justify-content: flex-start;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}

.titleBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5rem;
}
.title {
  width: 80%;
}

.infoBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.infoBox ul {
  margin: 5px;
}

.infoLabel {
  width: 100%;
}

.info {
  width: 100%;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  margin: 10px 0;
}

.spotInfo {
  width: 100%;
    padding: 0.5rem 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgb(161, 202, 238);
    border-radius: 10px;
}

.googleMapBtnBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0.5rem 0;
}

.googleMapBtn {
  width: auto;
  height: 1.5rem;
  font-size: 0.8rem;
  text-align: center;
  background-color: var(--blue-button-color);
  color: black;
  border-radius: 1rem;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}

.actionButtonBox {
  width: 100%;
  padding: 0 10px;
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(70px, 100%), 1fr));
  grid-gap: 1rem;
  align-items: start;
  justify-items: center;
}

.actionButton {
  color: black;
  width: 80px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.deleteButtonIconCircle,
.actionButtonIconCircle {
  border: none;
  border-radius: 50%;
  background-color: rgb(156, 153, 153);
}
.deleteButtonIconCircle {
  background-color: var(--red);
  color: #ffffff;
}
.actionButtonBox span {
  font-size: 0.8rem;
  margin-top: 5px;
}

.addButton {
  position: absolute;
  right: 10%;
  top: 16%;
}
.imageRowBox {
  display: grid;
  grid-template-columns: repeat(2, minmax(1rem, 1fr));
  gap: 1rem;
  margin: 1rem 0;
}
.imageBox {
  position: relative;
}

.spotImgText {
  position: absolute;
  bottom: 10px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 0.1rem 0.4rem;
  border-radius: 5px;
  font-size: 0.7rem;
}

.imageBox img {
  width: 200px;
  height: calc(200px * 0.75);
  object-fit: cover;
}

@media (max-width: 500px) {
  .addButtonBox {
    height: 68px;
    bottom: -1.2%;
  }
  .addButton {
    top: 15%;
    left: 70%;
  }
  .imageRowBox {
    gap: 0.5rem;
  }
  .imageBox img {
    width: 150px;
    height: calc(150px * 0.75);
  }
}

.listHeader {
  width: 100%;
  height: 40px;
  z-index: 3;
  position: fixed;
  top: 0;
  background-color: aliceblue;
  font-weight: 600;
  border-bottom: solid 1.5px var(--gray-color);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.spotInfoContents {
  width: 90%;
}
.categoryBox {
  display: flex;
  justify-content: start;
  align-items: start;
}
.categoryName {
  font-size: 0.8rem;
  background-color: var(--gray-color);
  padding: 1px 5px;
  border-radius: 10px;
  margin-bottom: 3px;
}
