.content {
  z-index: 2; /*scheduleMapBoxの上層*/
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputScheduleHeader {
  padding: 0 20px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  background-color: #ffffff;
  border-bottom: solid 1.5px var(--gray-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

@media (max-width: 500px) {
  .inputScheduleHeader {
    padding: 0 1rem;
  }
}
.asterisk {
  font-size: 0.6rem;
  color: #dd3636;
}

.inner {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 15px 15px 0px 0px;
  overflow: auto;
  box-shadow: 0 10px 25px 0 rgb(0 0 0 / 60%);
}
.errorsBox {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.errorMessageBox {
  width: 100%;
  height: 15px;
}

.pageTitle {
  text-align: center;
}

.addScheduleBox {
  width: 90%;
  margin: 0 auto;
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.contentBox {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
}

.subtitle {
  text-align: left;
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 0.4rem 0.8rem;
  letter-spacing: 0.1rem;
  border-radius: 5px;
  font-weight: 700;
}
@media (max-width: 500px) {
  .subtitle {
    font-weight: 600;
  }

  .contentBox {
    padding: 1rem;
  }
}

.uploadImageButton input {
  display: none;
}

.image {
  object-fit: cover;
  width: 35vw;
  height: calc(35vw * 0.75);
  margin-bottom: 1rem;
  display: none;
}

.scheduleImageBox {
  width: 35vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.closeButtonBox {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  top: 1%;
  right: 1%;
  background-color: var(--gray-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}
.closeBtn {
  color: #111111;
  font-size: 1.5rem;
  border: none;
  padding: 0;
}
/* モバイル・タブレット */
@media (max-width: 900px) {
  .scheduleImageBox {
    width: 40vw;
    height: auto;
  }
  .closeButtonBox {
    width: 2rem;
    height: 2rem;
  }
  .closeBtn {
    color: #111111;
    font-size: 1.3rem;
  }
  .image {
    width: 40vw;
    height: calc(40vw * 0.75);
  }
}

.details {
  text-align: left;
  color: #111111;
  font-weight: 700;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  display: flex;
  align-items: center;
}

.placeName {
  display: flex;
  width: 100%;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 0.5rem;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.crossBtn {
  border: none;
  font-size: 2rem;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  text-align: right;
}

.instruction {
  padding-bottom: 0.8rem;
}

.inputBox input,
.inputBox textarea {
  color: #111111;
  width: 100%;
}

.timeInput input {
  width: 100px;
  margin-right: 1rem;
}
.timeInput .inputBox {
  display: flex;
  justify-content: flex-start;
}
.timeDeleteBtn {
  color: #111111;
  border: none;
  font-size: 1.5rem;
  padding: 4px;
  text-align: right;
}
.addScheduleBox textarea {
  max-height: 700px;
  height: 250px;
  max-width: 100%;
  min-width: 100%;
}
.memo textarea {
  line-height: 1.7;
}

.placeBox {
  margin: auto 0;
}
.spot {
  width: 100%;
  padding: 1rem 2rem;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: aliceblue;
  border-radius: 10px;
}

.spotInfoContents{
  width: 90%;
}

.categoryName {
  font-size: 0.8rem;
  background-color: var(--gray-color);
  padding: 1px 5px;
  border-radius: 10px;
  margin-bottom: 3px;
}

.deleteButton {
  display: flex;
  background-color: #dddddd;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.4rem 0.4rem;
  z-index: 0;
  justify-content: center;
  align-items: center;
}

.faTimeRight {
  font-size: 1rem;
}

.categoryBox {
  display: flex;
  justify-content: start;
  align-items: start;
}

@media (max-width: 500px) {
  .spot {
    padding: 0.6rem 0.5rem;
  }
  .closeButton {
    width: 2rem;
    height: 2rem;
    font-size: 1.3rem;
  }
}
