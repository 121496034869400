.messageBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}
.errorCode {
  font-size: 3rem;
  margin-bottom: 0.1rem;
}
.title {
  margin: 1rem 0;
  font-size: 1.3rem;
}
.message {
  margin-top: 1.5rem;
  text-align: center;
}

.buttonBox {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
  width: 30%;
}

.link {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 700;
}

.bottomArea {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  flex-direction: column;
}
.imgBox {
  display: flex;
  width: 10%;
  align-items: center;
  justify-content: center;
}

.imgBox img {
  margin: 1.5rem;
  width: 100%;
}
/* モバイル */
@media (max-width: 500px) {
  .messageBox {
    margin: 0.5rem 1rem;
  }
  .errorCode {
    font-size: 2rem;
  }
  .message {
    font-size: 0.8rem;
  }
  .buttonBox {
    display: flex;
    margin-top: 1rem;
    justify-content: space-around;
    width: 80%;
  }
  .link {
    font-size: 0.8rem;
    font-weight: 600;
  }
  .bottomArea {
    margin-top: 2rem;
  }
  .imgBox {
    width: 30%;
  }
}
