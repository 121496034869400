.mapBox {
  position: relative;
  overflow: hidden;
  z-index: 1;
  height: 100%;
}

.searchBox {
  position: absolute;
  width: 70%;
  top: 15%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}

@media (max-width: 500px) {
  .searchBox {
    width: 85%;
  }
}
.searchBox input {
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0.5rem 0.5rem;
  border: solid 1px var(--gray-color);
  border-radius: 5px;
}

/* プランルームのマップ */
.planRoomMapBox {
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.deletePlaceButton {
  position: absolute;
  top: 0;
  right: 10px;
  width: 40px;
  height: 40px;
}
.times {
  color: #111111;
  width: 40px;
  height: 40px;
}
