.pageTitle {
  margin: 0;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  position: fixed;
  bottom: 5%;
  z-index: 1002;
  width: 70%;
  height: 90%;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 10px 25px 0 rgb(0 0 0 / 60%);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-wrap: break-word;
}

.inner {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-wrap: anywhere;
  overflow: auto;
  overflow-x: hidden;
  padding: 1rem;
}

.scheduleHeader {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.inner h3 {
  width: 100%;
  margin: 2rem 0;
  text-align: center;
}
.scheduleImage {
  object-fit: cover;
  width: 33vw;
  height: calc(33vw * 0.75);
}
@media (max-width: 500px) {
  .content {
    width: 100%;
    height: 100%;
    top: 11%;
    left: 0;
    padding-top: 0;
    border-radius: 1rem 1rem 0 0;
  }

  .inner {
    height: 90%;
    padding-bottom: 2rem;
  }

  .inner h3 {
    text-align: left;
    margin: 0.5rem 0;
  }

  .scheduleHeader {
    margin: 0.5rem 0;
  }
}
.scheduleContents {
  width: 100%;
  margin-top: 1rem;
}
.scheduleContents td {
  padding-bottom: 10px;
  text-align: left;
}
.scheduleContents tr {
  text-align: left;
}

.tdTitle {
  width: 100%;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  display: flex;
  overflow-wrap: anywhere;
}

.scheduleContents td {
  padding-bottom: 15px;
}

.scheduleContents li {
  list-style: none;
  margin-bottom: 0.5rem;
  text-align: right;
  color: black;
}

.infoBox {
  width: 100%;
  margin-bottom: 1rem;
}

.tdTitle {
  width: 100%;
  font-weight: 600;
}
.tdMemoBox {
  white-space: pre-wrap;
}

.tdImageBox {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}
.tdImageBox img {
  width: 250px;
  height: calc(250px * 0.75);
  object-fit: cover;
  margin-right: 1rem;
}

@media (max-width: 500px) {
  .scheduleImage {
    margin-top: 8px;
    object-fit: cover;
    width: 80vw;
    height: calc(80vw * 0.75);
  }

  .scheduleHeader {
    overflow-wrap: anywhere;
  }

  .tdTitle {
    font-weight: 600;
  }

  .tdImageBox {
    overflow-x: scroll;
  }
}
